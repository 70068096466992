// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** DEFAULT DESIGN VARIABLES FOR HISPALED**/
  --hl-spacing-xxs: 4px;
  --hl-spacing-xs: 8px;
  --hl-spacing-sm: 16px;
  --hl-spacing-md: 24px;
  --hl-spacing-lg: 48px;
  --hl-spacing-xl: 96px;

  /** OTHER UNKOWN COLOR FIXME: see where these colors must be placed into the theme**/
  --hl-color-primary-base-2: #648107;
  --hl-color-primary-base-2-rgb: 100, 129, 7;
  --hl-color-primary-base-3: #3e5602;
  --hl-color-primary-base-3-rgb: 62, 86, 2;
  --hl-color-secondary-2: #d911b1;
  --hl-color-secondary-2-rgb: 217, 17, 177;
  --hl-color-secondary-3: #870b6e;
  --hl-color-secondary-3-rgb: 135, 11, 110;

  /** MAIN COLORS */
  --ion-color-primary: #93b40e;
  --ion-color-primary-rgb: 147, 180, 14;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #819e0c;
  --ion-color-primary-tint: #9ebc26;

  --ion-color-secondary: #b40e93;
  --ion-color-secondary-rgb: 180, 14, 147;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #9e0c81;
  --ion-color-secondary-tint: #bc269e;

  /* SEMANTIC COLORS */

  --ion-color-info: #00b9f2;
  --ion-color-info-rgb: 0, 185, 242;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #00a3d5;
  --ion-color-info-tint: #1ac0f3;

  --ion-color-success: #23d14c;
  --ion-color-success-rgb: 35, 209, 76;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #1fb843;
  --ion-color-success-tint: #39d65e;

  --ion-color-warning: #f2a007;
  --ion-color-warning-rgb: 242, 160, 7;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d58d06;
  --ion-color-warning-tint: #f3aa20;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* TEXT COLORS */
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #000000;
  --ion-text-color-rgb: 0, 0, 0, 0.87;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;

  --ion-font-family: 'Inter', sans-serif;

  /** GRID **/
  --ion-grid-columns: 6;

  --ion-grid-padding: 16px;
  --ion-grid-padding-xs: var(--hl-spacing-md);
  --ion-grid-padding-sm: var(--hl-spacing-md);
  --ion-grid-padding-md: var(--hl-spacing-md);
  --ion-grid-padding-lg: var(--hl-spacing-md);
  --ion-grid-padding-xl: var(--hl-spacing-md);

  --ion-grid-column-padding: var(--hl-spacing-xs);
  --ion-grid-column-padding-xs: var(--hl-spacing-xs);
  --ion-grid-column-padding-sm: var(--hl-spacing-xs);
  --ion-grid-column-padding-md: var(--hl-spacing-xs);
  --ion-grid-column-padding-lg: var(--hl-spacing-xs);
  --ion-grid-column-padding-xl: var(--hl-spacing-xs);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #93b40e;
    --ion-color-primary-rgb: 147, 180, 14;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #819e0c;
    --ion-color-primary-tint: #9ebc26;

    --ion-color-secondary: #b40e93;
    --ion-color-secondary-rgb: 180, 14, 147;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #9e0c81;
    --ion-color-secondary-tint: #bc269e;

    --ion-color-info: #00b9f2;
    --ion-color-info-rgb: 0, 185, 242;
    --ion-color-info-contrast: #000000;
    --ion-color-info-contrast-rgb: 0, 0, 0;
    --ion-color-info-shade: #00a3d5;
    --ion-color-info-tint: #1ac0f3;

    --ion-color-success: #23d14c;
    --ion-color-success-rgb: 35, 209, 76;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #1fb843;
    --ion-color-success-tint: #39d65e;

    --ion-color-warning: #f2a007;
    --ion-color-warning-rgb: 242, 160, 7;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d58d06;
    --ion-color-warning-tint: #f3aa20;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
  .ios ion-popover {
    --ion-background-color: var(--ion-color-step-600, #ffffff);
  }
  .ios ion-datetime {
    --background: var(--ion-color-step-600, #ffffff);
    --background-rgb: var(--ion-color-step-600);
    --title-color: var(--ion-color-step-600, #666666);
  }
  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: var(--ion-background-color);

    --ion-toolbar-background: var(--ion-background-color);

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
