/* inter-100 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-100.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-100.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-200 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-200.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-200.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-300 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-300.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-regular - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-regular.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-500 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-500.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-600.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-700.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-800 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-800.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-800.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-900 - latin-ext_latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: local(''),
    url('../assets/fonts/inter/inter-v3-latin-ext_latin-900.woff2')
      format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('../assets/fonts/inter/inter-v3-latin-ext_latin-900.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
