/** TYPOGRAPHY ELEMENTS **/

.hl__heading {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

.hl__subheading {
  font-size: 1.25rem;
  line-height: 1.625rem;
  &--bold {
    font-weight: 700;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--ion-color-step-800);
  }
}

.hl__title {
  font-size: 1rem;
  line-height: 1.25rem;
  &--bold {
    font-weight: 700;
  }

  &--semi-bold {
    font-weight: 600;
  }

  &--medium {
    font-weight: 500;
  }
}

.hl__text {
  &--regular {
    font-weight: 400;
  }

  &--bold {
    font-weight: 700;
  }

  &--large {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &--medium {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  &--small {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
/** TYPOGRAPHY ELEMENTS **/

/** BUTTONS **/

ion-button {
  font-size: 1.125rem;
  line-height: 1.625rem;
  --border-radius: 8px;
  min-width: 325px;
  text-transform: none;

  &[color='primary'] {
    &::part(native) {
      background: var(--hl-color-primary-base-3, --ion-color-primary);
      color: var(--ion-background-color);
      @media (prefers-color-scheme: dark) {
        color: #ffffff;
      }
    }
  }

  //TODO: fix ripple color when ios seconday button is clicked. Currently the color is not visible because of the --background-activated-opacity//
  &[color='secondary'] {
    --background-activated-opacity: 0;

    &::part(native) {
      background: var(--ion-color-secondary-contrast, --ion-color-secondary);
      color: var(--hl-color-primary-base-3);
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--hl-color-primary-base-3);
    }
  }

  &[color='minimal'] {
    &::part(native) {
      background: var(--ion-color-secondary-contrast);
      color: var(--hl-color-primary-base-3);
      box-shadow: none;
    }
  }

  &[size='small'] {
    min-width: 96px;
  }

  &[size='x-small'] {
    min-width: 80px;
    font-size: 14px;
  }
}

@media (max-width: 374px) {
  ion-button {
    min-width: 250px;
  }
}

/** BUTTONS **/

/** CONTROLS **/

ion-toggle[color='secondary'] {
  --background: #92949c61;
  --handle-background: var(--ion-color-light);
  --handle-width: 20px;
  --handle-height: 20px;
  --handle-box-shadow: 0 3px 8px #cacbce, 0 3px 1px #cacbce;
}

ion-range {
  --pin-color: white;
  &::part(pin) {
    transform: translate3d(0px, -24px, 0px) scale(1);
  }
}

/** CONTROLS **/

/** HEADER **/

// Fix position on IOS devices so it fits on the viewport size.
.ios-safe-area {
  margin-top: constant(safe-area-inset-top); // for ios 11.1
  margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards
}

/** HEADER **/
